<template>
  <div>
    <Dialog
      type="error"
      :isShow="showDialog"
      @close-dialog="showDialog = false"
    >
      Oops! Please make your selections.
    </Dialog>
    <h1>Build your Milkshake</h1>
    <div class="overflow-section">
      <div name="fade" is="transition-group">
      <div
        class="table-row mb-1 fade-item"
        v-for="build in buildsMilkshake"
        :key="build.id"
      >
        <div class="table-cell-img">
          <img
            :src="require(`@/assets/images/${build.img}`)"
            :alt="build.title"
            width="80"
          >
        </div>
        <div class="table-cell-text">
          <div class="title-cell mb-1">
            <strong>{{ build.title }}</strong>
            <span @click="handleRemove(build.id)" class="close"></span>
          </div>
          <div class="cell-options d-flex">
            <Select
              type="flavors"
              :id="build.id"
              :options="flavors"
            />
            <Select
              type="milk"
              :id="build.id"
              :options="getMilk"
            />
          </div>
        </div>
      </div>
      </div>
      <div class="control-actions d-flex mt-1">
        <div class="holder">
          <button @click="handleAddProduct" class="btn btn-outline">Add a Shake</button>
          <button
            class="btn btn-outline"
            @click="addCart"
          >
            Add another item
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Buildermilkshake',
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters(['flavors', 'getMilk', 'buildsMilkshake', 'build']),
    getSize() {
      return this.buildsMilkshake.length > 0 ? this.buildsMilkshake[0].size : 'Small';
    },
    getImage() {
      return {
        Small: 'preview-milkshake-small.jpg',
        Large: 'preview-milkshake-large.jpg',
      };
    },
    getPrice() {
      return this.buildsMilkshake.length > 0 ? this.buildsMilkshake[0].price : 4.65;
    },
    getCatalogId() {
      return this.buildsMilkshake.length > 0 ? this.buildsMilkshake[0].catalogId : 0;
    },
    getVariantId() {
      return this.buildsMilkshake.length > 0 ? this.buildsMilkshake[0].variantId : 0;
    },
    getTitle() {
      return this.buildsMilkshake.length > 0 ? this.buildsMilkshake[0].title : 'Milkshake';
    },
  },
  methods: {
    handleAddProduct() {
      this.$store.commit('addToBuilder', {
        id: Date.now(),
        catalogId: this.getCatalogId,
        variantId: this.getVariantId,
        typeOfBuild: 'milkshake',
        title: this.getTitle,
        size: this.getSize,
        flavors: [],
        milk: [],
        img: this.getImage[this.getSize],
        price: this.getPrice,
      });
    },
    handleRemove(id) {
      this.$store.commit('remove', {
        id,
        el: ['builds'],
      });
    },
    addCart() {
      this.$store.dispatch('validateBuild')
        .then((isValid) => {
          if (isValid) {
            this.$store.commit('addToCart', this.build);
            this.$router.push({ name: 'Home' });
          } else {
            this.showDialog = true;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
