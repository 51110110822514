<template>
  <div class="page-content">
    <div class="container">
      <Dialog
        type="error"
        :isShow="showDialog"
        @close-dialog="showDialog = false"
      >
        Oops! Please make your selections.
      </Dialog>
      <div v-if="build && build.length">
        <components
          :is="builderProduct"
        ></components>
        <div class="controls">
          <button
            class="btn"
            @click="addCart"
          >
            All done?
          </button>
        </div>
      </div>
      <div v-else class="container">
        <h1>Oops, this doesn't look right!</h1>
        <Router-link :to="$options.links.home" class="btn">Start Over</Router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Builderscoop from '@/components/Builderscoop';
import Buildermilkshake from '@/components/Buildermilkshake';
import Dialog from '@/components/Dialog';
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
  },
  name: 'Builder',
  components: {
    Builderscoop,
    Buildermilkshake,
    Dialog,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters(['flavors', 'build']),
    builderProduct() {
      return `Builder${this.$route.params.name}`;
    },
  },
  methods: {
    addCart() {
      this.$store.dispatch('validateBuild')
        .then((isValid) => {
          if (isValid) {
            this.$store.commit('addToCart', this.build);
            this.$router.push({ name: 'Cart' });
          } else {
            this.showDialog = true;
          }
        });
    },
  },
};
</script>

<style scoped>

</style>
